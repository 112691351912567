import React from 'react';
import './../../styles/whale.scss';

const Whale: React.FC = () => {
    return (
        <div className="whale-container">
            {'(._.)>3'}
        </div>
    );
};

export default Whale;
