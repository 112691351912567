import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Whale from './whale/Whale'; // adjust the path if necessary

const App: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Whale />} />
                {/* other routes */}
            </Routes>
        </Router>
    );
};

export default App;
